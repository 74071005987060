import img3 from "../../assets/department_images/EI/3.png";
import img7 from "../../assets/department_images/EI/7.jpeg";
import img9 from "../../assets/department_images/EI/9.png";
import img10 from "../../assets/department_images/EI/10.png";
import img11 from "../../assets/department_images/EI/11.png";
import img13 from "../../assets/department_images/EI/13.png";
import img14 from "../../assets/department_images/EI/14.jpeg";

const AeiEventData = [
  {
    event_id: 201,
    name: "Technical Exhibition",
    description:
      "A platform showcasing innovative technology in the automation/instrumentation field.",
    event_type: "Group / Individual",
    fee: "0",
    student1: "Mr. Rony Sibi",
    s1_phone: "+91 94476 88158",
    student2: "Mr. Sreyas",
    s2_phone: "Not Provided",
    staff: "Mr. Thomas M S",
    prize: true,
    dept: "aei",
    reg: false,
    group: true,
    venue: "Admin Block",
    time: "9:30 AM",
    event_image: img10,
  },
  {
    event_id: 202,
    name: "Treasure Hunt",
    description:
      "To boost both your logic and technique, the idea of hunting for hidden treasures with different level.",
    event_type: "Group",
    fee: "100",
    student1: "Mr. Deepak Haridas",
    s1_phone: "85478 87198",
    student2: "Mr. Abhiroop T C",
    s2_phone: "Not Provided",
    staff: "Ms. Shamya A",
    prize: true,
    dept: "aei",
    reg: false,
    group: true,
    venue: "Admin Block",
    time: "9:30 AM",
    event_image: img13,
  },
  {
    event_id: 203,
    name: "Paper Presentation",
    description:
      "An individual competition where each participant is required to make a paper and then present it in front of the juries.",
    event_type: "Individual",
    fee: "0",
    student1: "Ms. Jasmine P T K",
    s1_phone: "94976 19921",
    student2: "Ms. Adithya K C",
    s2_phone: "Not Provided",
    staff: "Ms. Reshma K V",
    prize: true,
    dept: "aei",
    reg: false,
    group: false,
    venue: "Admin Block",
    time: "9:30 AM",
    event_image: img3,
  },
  {
    event_id: 204,
    name: "Technical Quiz",
    description:
      "A contest in which participants test what they know technically by answering questions.",
    event_type: "Individual",
    fee: "0",
    student1: "Ms. Merin Saji",
    s1_phone: "+91 96057 66939",
    student2: "Ms. Keerthana Kannan",
    s2_phone: "Not Provided",
    staff: "Ms. Reshma K V",
    prize: true,
    dept: "aei",
    reg: false,
    group: false,
    venue: "Admin Block",
    time: "9:30 AM",
    event_image: img7,
  },
  {
    event_id: 205,
    name: "Robo Soccer",
    description:
      "A Manual Robot which can compete on an arena specially designed for robotic soccer match.",
    event_type: "Individual",
    fee: "100",
    student1: "Mr. Noel Biju",
    s1_phone: "92071 67166",
    student2: "Mr. Alin K S",
    s2_phone: "Not Provided",
    staff: "Mr. Jollykutty sebastian",
    prize: true,
    dept: "aei",
    reg: false,
    group: false,
    venue: "Admin Block",
    time: "9:30 AM",
    event_image: img9,
  },
  {
    event_id: 206,
    name: "Robo Race",
    description:
      "A robot either wired or wireless within the specified area that can be operated manually and can travel.",
    event_type: "Individual",
    fee: "100",
    student1: "Mr. Muhammed Amal",
    s1_phone: "+91 8138-008550",
    student2: "Mr. Muhammed Nihad",
    s2_phone: "Not Provided",
    staff: "Mr. Jollykutty sebastian",
    prize: true,
    dept: "aei",
    reg: false,
    group: false,
    venue: "Admin Block",
    time: "9:30 AM",
    event_image: img10,
  },
  {
    event_id: 207,
    name: "Arcade Games",
    description: "level up every game to reach the final game.",
    event_type: "Individual",
    fee: "50",
    student1: "Mr. Alen Johns",
    s1_phone: "+91 6282-302366",
    student2: "Mr. Athul P P",
    s2_phone: "Not Provided",
    staff: "Ms. Shamya A",
    prize: true,
    dept: "aei",
    reg: false,
    group: false,
    venue: "Admin Block",
    time: "9:30 AM",
    event_image: img11,
  },
  {
    event_id: 208,
    name: "Mobile Game",
    description: "Individual mobile multiplayer gaming competition.",
    event_type: "Individual",
    fee: "50",
    student1: "Mr. Muhammed Razeen",
    s1_phone: "+91 80866 40466",
    student2: "Mr. Muhammed Arshad",
    s2_phone: "Not Provided",
    staff: "Ms. Shamya A",
    prize: true,
    dept: "aei",
    reg: false,
    group: false,
    venue: "Admin Block",
    time: "9:30 AM",
    event_image: img10,
  },
  {
    event_id: 209,
    name: "Shootout",
    description: "Penalty shootout game.",
    event_type: "Individual",
    fee: "20",
    student1: "Mr. Anurag E V",
    s1_phone: "+91 86068 80170",
    student2: "Mr. Adith T P",
    s2_phone: "Not Provided",
    staff: "Mr. Thomas M S",
    prize: true,
    dept: "aei",
    reg: false,
    group: false,
    venue: "Admin Block",
    time: "9:30 AM",
    event_image: img13,
  },
  {
    event_id: 210,
    name: "Basket Throw",
    description: "Throw balls consecutively into a basket to win.",
    event_type: "Individual",
    fee: "20",
    student1: "Mr. Amal Manoj",
    s1_phone: "+91 85906 77476",
    student2: "Mr. Kiran Raj",
    s2_phone: "Not Provided",
    staff: "Mr. Thomas M S",
    prize: true,
    dept: "aei",
    reg: false,
    group: false,
    venue: "Admin Block",
    time: "9:30 AM",
    event_image: img3,
  },
  {
    event_id: 211,
    name: "Cryptic Hunt",
    description:
      "An interactive game that requires participants to solve a clue ,to pass to the arm wrestling stage.",
    event_type: "Individual",
    fee: "20",
    student1: "Anand Sajeevan",
    s1_phone: "+91 99957 34587",
    student2: "Mr. Manav Rajeev",
    s2_phone: "Not Provided",
    staff: "Mr. Thomas M S",
    prize: true,
    dept: "aei",
    reg: false,
    group: false,
    venue: "Admin Block",
    time: "9:30 AM",
    event_image: img14,
  },
];

export default AeiEventData;
