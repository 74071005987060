import img6 from "../../assets/department_images/CE/6.png";
import img4 from "../../assets/department_images/CE/4.png";
import img8 from "../../assets/department_images/CE/8.png";
import img9 from "../../assets/department_images/CE/9.png";
import img11 from "../../assets/department_images/CE/11.png";
import img14 from "../../assets/department_images/CE/14.png";
const CeEventData = [
  {
    event_id: 401,
    name: "Model",
    description: "Model building event for Group CE.",
    event_type: "Model",
    fee: "0",
    student1: "Not Provided",
    s1_phone: "Not Provided",
    staff: "Not Provided",
    prize: false,
    dept: "ce",
    reg: false,
    group: true,
    venue: "Not Provided",
    time: "Full Day",
    event_image: img6,
  },
  {
    event_id: 402,
    name: "Treasure Hunt - One Piece",
    description:
      "Embark on an exhilarating adventure with cunning clues and hidden surprises. Unravel the mysteries to discover the ultimate prize! (Groups of 3)",
    event_type: "Treasure Hunt",
    fee: "50",
    student1: "GOPIKA NS",
    s1_phone: "8157035923",
    staff: "Not Provided",
    prize: true,
    dept: "ce",
    reg: false,
    group: true,
    venue: "Auditorium",
    time: "10:30 AM - 1:00 PM",
    event_image: img14,
  },
  {
    event_id: 403,
    name: "Quiz - Mind Maze",
    description: "Unleash your knowledge in the ultimate quiz quest!",
    event_type: "Quiz",
    fee: "30",
    student1: "NANDANA RAJAN",
    s1_phone: "9961129602",
    staff: "Not Provided",
    prize: true,
    dept: "ce",
    reg: false,
    group: false,
    venue: "Auditorium",
    time: "Full Day",
    event_image: img9,
  },
  {
    event_id: 404,
    name: "Bridge Making - Popsicle Ponte",
    description:
      "Participants (Groups of 3) build the best bridge using popsicles. The bridge with the highest load capacity wins.",
    event_type: "Competition",
    fee: "30",
    student1: "ASWANTH V",
    s1_phone: "9061835998",
    staff: "ANITHA BABU",
    prize: true,
    dept: "ce",
    reg: false,
    group: true,
    venue: "Auditorium",
    time: "11:30 AM - 1:00 PM",
    event_image: img6,
  },
  {
    event_id: 405,
    name: "Ring Master",
    description:
      "Aim, swing, and score in the ultimate throwing ring extravaganza!",
    event_type: "Game",
    fee: "30",
    student1: "GAUTAM S SREEDHAR",
    s1_phone: "9207304290",
    staff: "Not Provided",
    prize: true,
    dept: "ce",
    reg: false,
    group: false,
    venue: "Auditorium",
    time: "Full Day",
    event_image: img4,
  },
  {
    event_id: 406,
    name: "Shatter Strike",
    description: "Precision ball throwing for a glass-shattering thrill!",
    event_type: "Game",
    fee: "30",
    student1: "AKSHAY K ASHOK",
    s1_phone: "7994940218",
    staff: "Not Provided",
    prize: true,
    dept: "ce",
    reg: false,
    group: false,
    venue: "Auditorium",
    time: "Full Day",
    event_image: img8,
  },
  {
    event_id: 407,
    name: "Pyramid",
    description:
      "Challenge your spatial skills by constructing a sturdy glass pyramid.",
    event_type: "Game",
    fee: "30",
    student1: "ABHINAND RAJEEV",
    s1_phone: "6235024953",
    staff: "Not Provided",
    prize: true,
    dept: "ce",
    reg: false,
    group: false,
    venue: "Auditorium",
    time: "Full Day",
    event_image: img9,
  },
  {
    event_id: 408,
    name: "Wicket Whiz",
    description: "Precision throws in the ultimate wicket challenge!",
    event_type: "Game",
    fee: "30",
    student1: "ARJUN C",
    s1_phone: "7306829792",
    staff: "Not Provided",
    prize: true,
    dept: "ce",
    reg: false,
    group: false,
    venue: "Auditorium",
    time: "Full Day",
    event_image: img14,
  },
  {
    event_id: 409,
    name: "Gamilitia",
    description: "Mini Militia game played solo or in squads.",
    event_type: "Game",
    fee: "30",
    student1: "Sinan Abdul Gafoor",
    s1_phone: "8075974075",
    staff: "Not Provided",
    prize: true,
    dept: "ce",
    reg: false,
    group: true,
    venue: "Auditorium",
    time: "10:30 AM - 11:30 AM",
    event_image: img11,
  },
  {
    event_id: 410,
    name: "Spot Kick Frenzy",
    description: "Score or save – every penalty counts!",
    event_type: "Game",
    fee: "30",
    student1: "ALLEN JOSEPH",
    s1_phone: "8590796531",
    staff: "Not Provided",
    prize: true,
    dept: "ce",
    reg: false,
    group: false,
    venue: "Auditorium",
    time: "Full Day",
    event_image: img8,
  },
  {
    event_id: 411,
    name: "Bad Basket",
    description:
      "Players showcase skills in an electrifying basketball showdown.",
    event_type: "Game",
    fee: "30",
    student1: "FEBIN TOM THOMAS",
    s1_phone: "9447910320",
    staff: "Not Provided",
    prize: true,
    dept: "ce",
    reg: false,
    group: false,
    venue: "Auditorium",
    time: "Full Day",
    event_image: img6,
  },
  {
    event_id: 412,
    name: "Dead Hang",
    description:
      "Find strength in endurance by gripping the bar for as long as possible.",
    event_type: "Game",
    fee: "30",
    student1: "DANI P JOJI",
    s1_phone: "9447880661",
    staff: "Not Provided",
    prize: true,
    dept: "ce",
    reg: false,
    group: false,
    venue: "Auditorium",
    time: "Full Day",
    event_image: img14,
  },
];
export default CeEventData;
