import img1 from "../../assets/department_images/CSCY/1.png";
import img6 from "../../assets/department_images/CSCY/6.png";
import img9 from "../../assets/department_images/CSCY/9.png";
import img17 from "../../assets/department_images/CSCY/17.png";
import img18 from "../../assets/department_images/CSCY/18.png";
import img20 from "../../assets/department_images/CSCY/20.png";
import img24 from "../../assets/department_images/CSCY/24.png";
import img26 from "../../assets/department_images/CSCY/26.jpg";
import img28 from "../../assets/department_images/CSCY/28.png";
const CscyEventData = [
  {
    event_id: 701,
    name: "Cyber Hunt",
    description:
      "Find 4 QR codes, solve puzzles to collect letters and numbers. Use the letters as a username and numbers as the password to log into the server, hit 'Stop,' and win!",
    event_type: "Competition",
    fee: "15",
    student1: "Anirudh",
    s1_phone: "8606594032",
    staff: "Raheed Ahamed Azad V",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground S5 CSCY",
    time: "09:30 am to 03:30 PM",
    event_image: img1,
  },
  {
    event_id: 702,
    name: "Chakravyuham",
    description:
      "Uncovering and exploiting vulnerabilities in a designated web application within a set time limit.",
    event_type: "Competition",
    fee: "15",
    student1: "Sandra",
    s1_phone: "8138963977",
    staff: "Aswathy V",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground S5 CSCY",
    time: "09:30 am to 03:30 PM",
    event_image: img6,
  },
  {
    event_id: 703,
    name: "Decode-a-thon",
    description:
      "A fun and entertaining event where participants race against time to decrypt cipher messages. Learn cryptography techniques!",
    event_type: "Competition",
    fee: "15",
    student1: "Aksh Jolly",
    s1_phone: "8113844603",
    staff: "Sr.Reema Jose",
    prize: true,
    dept: "cscy",
    reg: false,
    group: true,
    venue: "Ground S5 CSCY",
    event_image: img9,
    time: "09:30 am to 03:30 PM",
  },
  {
    event_id: 704,
    name: "Blind Coding",
    description:
      "Participants must write a program on their screens. The person with the fewest errors will win.",
    event_type: "Competition",
    fee: "15",
    student1: "Harshith",
    s1_phone: "9605760762",
    staff: "Jinsu Anna John",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "2ND PG Lab",
    time: "10:30 AM to 11:30 AM",
    event_image: img17,
  },
  {
    event_id: 705,
    name: "Go Master",
    description:
      "Unlock the power of Go! A hands-on workshop to build real-world applications with a certificate.",
    event_type: "Workshop",
    fee: "0",
    student1: "Haritheertha",
    s1_phone: "7593917849",
    staff: "Anu Treesa George",
    prize: false,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "2ND PG Lab",
    time: "09:30 AM to 10:30 AM",
    event_image: img18,
  },
  {
    event_id: 706,
    name: "2048",
    description:
      " 2048+-*/ is an advanced, mentally stimulating puzzle gamethat combines arithmetic operations with strategic thinking.Building on the classic 2048 game, this version introduces addition, subtraction, multiplication, and division to challenge your mathematical skills.",
    event_type: "Competition",
    fee: "15",
    student1: "Shreya Sreekumar",
    s1_phone: "9037064843",
    staff: "Mafnita KK",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground S3 CSCY",
    time: "09:30 am to 01:30 PM",
    event_image: img6,
  },
  {
    event_id: 707,
    name: "Cyber Quest",
    description:
      "Unlock your cyber potential and test your expertise in security fundamentals. Test your knowledge through online with our cyber security quiz which covers the basic essentials that need to know.",
    event_type: "Competition",
    fee: "15",
    student1: "Alka Sajeevan P",
    s1_phone: "9074281356",
    staff: "Anu Treesa George",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground S3 CSCY",
    time: "09:30 am to 01:30 PM",
    event_image: img20,
  },
  {
    event_id: 708,
    name: "Crypto Clash",
    description:
      " Test your code breaking skills in this addictive cryptogram puzzle game! Participants will be given encrypted messages. Each letter in the puzzle has been replaced with another, and the task is to crack the code and reveal the hidden message.",
    event_type: "Competition",
    fee: "15",
    student1: "Varna OV",
    s1_phone: "6238634461",
    staff: "Mafnita KK",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground S3 CSCY",
    time: "09:30 am to 01:30 PM",
    event_image: img24,
  },
  {
    event_id: 709,
    name: "Bug Bounty",
    description:
      " Put your debugging skills to the test. Try and debug different levels of programming puzzles with bounded time and executions.",
    event_type: "Competition",
    fee: "15",
    student1: "Hridhay",
    s1_phone: "918301041921",
    staff: "Aswathy V",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground Lab 8",
    time: "02:30 PM to 03:30 PM",
    event_image: img9,
  },
  {
    event_id: 710,
    name: "Speed Typing",
    description: "Type the data as fast as you can to win the prize",
    event_type: "Competition",
    fee: "15",
    student1: "Amith",
    s1_phone: "918848648848",
    staff: "Sr.Reema Jose",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground S3 CSCY",
    time: "10:00 AM to 11:00 AM",
    event_image: img26,
  },
  {
    event_id: 711,
    name: "Cyber Quiz",
    description: "A quiz on cyber security.",
    event_type: "Competition",
    fee: "15",
    student1: "Sreyas",
    s1_phone: "918136957257",
    staff: "Raheed Ahamed Azad V",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground Lab 8",
    event_image: img28,
    time: "01:30 PM to 02:30 PM",
  },
  {
    event_id: 712,
    name: "Multi-War",
    description: "A tournament of Stumble Guys.",
    event_type: "Competition",
    fee: "15",
    student1: "Fabin",
    s1_phone: "916238533093",
    staff: "Aswathy V",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground S5 CSCY",
    time: "10:30 AM to 11:30 AM",
    event_image: img1,
  },
  {
    event_id: 713,
    name: "Tech Debate",
    description: "Team of two debates on technological topics.",
    event_type: "Competition",
    fee: "15",
    student1: "Rizana",
    s1_phone: "919074306520",
    staff: "Sr.Reema Jose",
    prize: true,
    dept: "cscy",
    reg: false,
    group: true,
    venue: "2ND PG Lab",
    time: "02:30 PM to 03:30 PM",
    event_image: img6,
  },
  {
    event_id: 714,
    name: "Dumbcharades",
    description: "A random text or image is given for participants to act out.",
    event_type: "Competition",
    fee: "15",
    student1: "Arya",
    s1_phone: "6235609201",
    staff: "Jinsu Anna John",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground S5 CSCY",
    time: "12:30 PM to 03:30 PM",
    event_image: img9,
  },
  {
    event_id: 715,
    name: "Cryptic Hunting",
    description:
      " Presenting you with a mind boggling virtual maze. Put all your skill sets to the test to reach the end, including your own sanity.",
    event_type: "Competition",
    fee: "15",
    student1: "Adnan",
    s1_phone: "916235405122",
    staff: "Mafnita KK",
    prize: true,
    dept: "cscy",
    reg: false,
    group: true,
    venue: "Ground Lab 8",
    time: "09:30 AM to 10:30 AM",
    event_image: img17,
  },
  {
    event_id: 716,
    name: "Web Cloner",
    description:
      " Participants will replicate a given website using only HTML and CSS, focusing on layout, design, and responsive elements",
    event_type: "Competition",
    fee: "15",
    student1: "Adithya GK",
    s1_phone: "7012958504",
    staff: "Jinsu Anna John",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "2ND PG Lab",
    time: "11:30 AM to 12:30 PM",
    event_image: img18,
  },
  {
    event_id: 717,
    name: "Rocket League",
    description:
      " Experience high-octane fun in our mini Rocket League game, where teams face off in thrilling, soccer-style matches with rocket-powered cars",
    event_type: "Competition",
    fee: "15",
    student1: "Adithya GK",
    s1_phone: "7012958504",
    staff: "Anu Treesa George",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground S5 CSCY",
    time: "09:30 am to 03:30 PM",
    event_image: img20,
  },
  {
    event_id: 718,
    name: "Ball Throw",
    description:
      " Shooting from four points within the boundary of the 2 shots square, Shots starting from the baseline to the real two shots shooting point. A player have to make 3 shots in the 4 chance will be the winner and prizes will be awarded. The main rule is they cannot lift their foot completely from the ground while shooting.(no jump)",
    event_type: "Competition",
    fee: "15",
    student1: "Sarang",
    s1_phone: "7909253003",
    staff: "Raheed Ahamed Azad V",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "NBA",
    time: "09:30 AM to 12:30 PM",
    event_image: img18,
  },
  {
    event_id: 719,
    name: "CodeQuest",
    description:
      "Python Quiz Challenge is an interactive Python-based quiz game, designed specifically for test participants’ knowledge of Python programming and general coding principles.",
    event_type: "Competition",
    fee: "15",
    student1: "Saniya Sudhan",
    s1_phone: "6238377855",
    staff: "Aswathy V",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground Lab 8",
    time: "12:30 PM to 01:30 PM",
    event_image: img26,
  },
  {
    event_id: 720,
    name: "Efootball 25",
    description:
      "A soccer game from Konami, released in 2024, based on Pro Evolution Soccer.",
    event_type: "Competition",
    fee: "15",
    student1: "Abhishek",
    s1_phone: "9496954119",
    staff: "Anu Treesa George",
    prize: true,
    dept: "cscy",
    reg: false,
    group: true,
    venue: "Ground S5 CSCY",
    time: "09:30 AM to 11:30 AM",
    event_image: img20,
  },
  {
    event_id: 721,
    name: "Stego Hunt",
    description:
      "Uncover hidden data in files, crack the password using clues, and extract the information. First to succeed wins!",
    event_type: "Competition",
    fee: "15",
    student1: "Sreelakshmi",
    s1_phone: "9778114578",
    staff: "Raheed Ahamed Azad V",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground Lab 8",
    time: "11:30 AM to 12:30 PM",
    event_image: img1,
  },
  {
    event_id: 722,
    name: "Relay Coding",
    description:
      " Teams take turns coding a solution to a given problem, with each member building on the previous one's work. The fastest and most accurate team wins!",
    event_type: "Competition",
    fee: "15",
    student1: "Meenu S Nair",
    s1_phone: "9744857411",
    staff: "Aswathy V",
    prize: true,
    dept: "cscy",
    reg: false,
    group: true,
    venue: "Ground Lab 8",
    time: "12:30 PM to 01:30 PM",
    event_image: img6,
  },
  {
    event_id: 723,
    name: "DeepFake",
    description:
      " Dive into our DeepFake exhibition to uncover the technology, implications, and ways to combat misinformation in the digital age!",
    event_type: "Exhibition",
    fee: "0",
    student1: "Melgibson",
    s1_phone: "9061354921",
    staff: "Sr.Reema Jose",
    prize: false,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground S5 CSCY",
    time: "09:30 am to 03:30 PM",
    event_image: img9,
  },
  {
    event_id: 724,
    name: "Capture the Flag (CTF)",
    description:
      " Join our Capture the Flag (CTF) Competition to test your cybersecurity skills! Solve puzzles in cryptography and web security to capture flags and win prizes",
    event_type: "Competition",
    fee: "15",
    student1: "Adarsh",
    s1_phone: "918301957157",
    staff: "Jinsu Anna John",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "2ND PG Lab",
    time: "12:30 PM to 01:30 PM",
    event_image: img24,
  },
  {
    event_id: 725,
    name: "Reverse Coding",
    description:
      "Participants will be given a specific output and must decipher the minimal, most efficient code to achieve it",
    event_type: "Competition",
    fee: "15",
    student1: "Niranjan",
    s1_phone: "918714575670",
    staff: "Mafnita KK",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "2ND PG Lab",
    time: "01:30 PM to 02:30 PM",
    event_image: img9,
  },
  {
    event_id: 726,
    name: "Security Tools",
    description:
      " Participants will gain hands-on experience with popular software and techniques used for threat detection, vulnerability assessment, and secure networking with certificate",
    event_type: "Workshop",
    fee: "0",
    student1: "Faheema",
    s1_phone: "9207690581",
    staff: "Jinsu Anna John",
    prize: false,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground Lab 8",
    time: "10:30 AM to 11:30 AM",
    event_image: img26,
  },
  {
    event_id: 727,
    name: "Kich Maniaa",
    description:
      " Join the excitement of a live match in this single-player event where you can beat the high score in Dream Soccer! Test your skills and strategy to climb the leaderboard and show off your talent",
    event_type: "Competition",
    fee: "15",
    student1: "Edwin",
    s1_phone: "7012535604",
    staff: "Anu Treesa George",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground S5 CSCY",
    time: "09:30 am to 03:30 PM",
    event_image: img1,
  },
  {
    event_id: 728,
    name: "Control Master",
    description:
      "In a thrilling aerial competition, piloting agile helicopters through challenging courses filled with obstacles and tight turns",
    event_type: "Competition",
    fee: "15",
    student1: "Mathew",
    s1_phone: "7510795138",
    staff: "Raheed Ahamed Azad V",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground S5 CSCY",
    time: "09:30 am to 03:30 PM",
    event_image: img28,
  },
  {
    event_id: 729,
    name: "Tech Jam",
    description:
      " Tech Jam is an innovative competition where tech enthusiasts come together to create, collaborate, and showcase cutting-edge solutions, pushing the boundaries of technology in just 48 hours",
    event_type: "Competition",
    fee: "15",
    student1: "Anugraha",
    s1_phone: "7510704023",
    staff: "Mafnita KK",
    prize: true,
    dept: "cscy",
    reg: false,
    group: true,
    venue: "Ground S5 CSCY",
    time: "02:30 PM to 03:30 PM",
    event_image: img26,
  },
  {
    event_id: 730,
    name: "Beat the Game",
    description:
      "Unfair Mario offers hidden traps and surprises at every step.",
    event_type: "Competition",
    fee: "15",
    student1: "Anuvindh",
    s1_phone: "8848245833",
    staff: "Sr.Reema Jose",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground S5 CSCY",
    time: "09:30 am to 03:30 PM",
    event_image: img6,
  },
  {
    event_id: 731,
    name: "Social Engineering",
    description:
      " fun game where players ask yes-or-no questions to reveal a secret password. The goal is to crack the code using clever deduction before running out of questions",
    event_type: "Competition",
    fee: "15",
    student1: "Arya Dhanesh",
    s1_phone: "7012165724",
    staff: "Raheed Ahamed Azad V",
    prize: true,
    dept: "cscy",
    reg: false,
    group: false,
    venue: "Ground S3 CSCY",
    time: "09:30 am to 01:00 PM",
    event_image: img20,
  },
];
export default CscyEventData;
