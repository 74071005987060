import img7 from "../../assets/department_images/EEE/7.png";
import img8 from "../../assets/department_images/EEE/8.png";
import img10 from "../../assets/department_images/EEE/10.jpeg";
import img11 from "../../assets/department_images/EEE/11.png";
import img14 from "../../assets/department_images/EEE/14.png";
import img16 from "../../assets/department_images/EEE/16.jpeg";
import img23 from "../../assets/department_images/EEE/23.jpeg";
import img26 from "../../assets/department_images/EEE/26.jpg";
import img32 from "../../assets/department_images/EEE/32.jpeg";
import img38 from "../../assets/department_images/EEE/38.png";

const EeeEventData = [
  {
    event_id: 1,
    name: "Project competition",
    description: "Competition of circuit-based projects.",
    event_type: "Technical competition",
    fee: "0",
    student1: "Sallap Pradeep",
    s1_phone: "8078270268",
    staff: "Ms. Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "Electrical lab complex",
    time: "na",
    event_image: img7,
  },
  {
    event_id: 2,
    name: "Tech spark",
    description: "Quiz competition.",
    event_type: "Technical quiz",
    fee: "0",
    student1: "Aphin George",
    s1_phone: "7012335523",
    staff: "Ms.Tintu Geroge",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: false,
    venue: "Online",
    time: "na",
    event_image: img8,
  },
  {
    event_id: 3,
    name: "Electro talks",
    description: "Presentation of technical topics.",
    event_type: "Technical topic presentation",
    fee: "0",
    student1: "Swathipriya",
    s1_phone: "8714504573",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: false,
    venue: "Online",
    time: "na",
    event_image: img10,
  },
  {
    event_id: 4,
    name: "Spark clash",
    description: "Debate on technical topics.",
    event_type: "Technical debate",
    fee: "0",
    student1: "Elizabeth",
    s1_phone: "7560859522",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "S7 EEE",
    time: "na",
    event_image: img11,
  },
  {
    event_id: 5,
    name: "Power pitch",
    description: "Event based on ideas.",
    event_type: "Idea pitching competition",
    fee: "0",
    student1: "Souraj KPS",
    s1_phone: "8547487101",
    staff: "Ms. Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "Online",
    time: "na",
    event_image: img14,
  },
  {
    event_id: 6,
    name: "InnovateX",
    description: "Propose a new project topic.",
    event_type: "Project proposal",
    fee: "0",
    student1: "Anto Joseph",
    s1_phone: "7510434230",
    staff: "Ms. Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "Online",
    time: "na",
    event_image: img23,
  },
  {
    event_id: 7,
    name: "Debug surge",
    description: "Analyzing circuits, detection, and correction of errors.",
    event_type: "Circuit debugging",
    fee: "30",
    student1: "Amaljith PP",
    s1_phone: "8590240971",
    staff: "Ms. Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "Electrical lab complex",
    time: "na",
    event_image: img26,
  },
  {
    event_id: 8,
    name: "The charge chase",
    description: "Solving electrical wiring challenges.",
    event_type: "Circuit race",
    fee: "30",
    student1: "Diya C",
    s1_phone: "7736831497",
    staff: "Ms. Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "Electrical lab complex",
    time: "na",
    event_image: img32,
  },
  {
    event_id: 9,
    name: "L Board",
    description: "Competition to showcase driving skills.",
    event_type: "Game competition",
    fee: "30",
    student1: "Jithin Mohan",
    s1_phone: "9074362926",
    staff: "Ms. Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "S7 EEE",
    time: "na",
    event_image: img38,
  },
  {
    event_id: 10,
    name: "Robo racer build",
    description: "Assembling of Robo Car.",
    event_type: "Robo car assemble",
    fee: "50",
    student1: "Ajith Mathew",
    s1_phone: "6235385080",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "Electrical lab complex",
    time: "na",
    event_image: img7,
  },
  {
    event_id: 11,
    name: "Mecha strikers",
    description: "Robots compete against each other in a soccer match.",
    event_type: "Robo soccer",
    fee: "30",
    student1: "Rohith Sajeev",
    s1_phone: "7736906096",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "Electrical lab complex",
    time: "na",
    event_image: img8,
  },
  {
    event_id: 12,
    name: "Wave warriors",
    description: "2 robots, a land bot and a water bot.",
    event_type: "Robo Oceana",
    fee: "40",
    student1: "Fareed MK",
    s1_phone: "9567077355",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "Gandhi pond",
    time: "na",
    event_image: img10,
  },
  {
    event_id: 13,
    name: "Robo rush",
    description: "Robot car racing competition.",
    event_type: "Robo race",
    fee: "30",
    student1: "Simon Joseph",
    s1_phone: "9061136839",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "College ground",
    time: "na",
    event_image: img11,
  },
  {
    event_id: 14,
    name: "Aqua amps",
    description: "To create a water rocket.",
    event_type: "Aqua strike",
    fee: "20",
    student1: "Alan Thomas",
    s1_phone: "6282053950",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "Gandhi pond",
    time: "na",
    event_image: img14,
  },
  {
    event_id: 15,
    name: "Flipmaster",
    description: "Flipping of the bottle.",
    event_type: "Game",
    fee: "20",
    student1: "Manu Joseph",
    s1_phone: "6235064145",
    staff: "Ms. Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "Electrical lab complex",
    time: "na",
    event_image: img16,
  },
  {
    event_id: 16,
    name: "Highfly hustle",
    description: "Paper plane flying competition.",
    event_type: "Game",
    fee: "10",
    student1: "Alan K",
    s1_phone: "6282053950",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "Electrical lab complex",
    time: "na",
    event_image: img23,
  },
  {
    event_id: 17,
    name: "Toss'n target",
    description: "Throwing circular ring at a stick.",
    event_type: "Ring toss",
    fee: "10",
    student1: "Ashin Paul",
    s1_phone: "8547887673",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: false,
    venue: "Electrical lab complex",
    time: "na",
    event_image: img26,
  },
  {
    event_id: 18,
    name: "Net Busters",
    description: "Hit the ball into the post.",
    event_type: "Penalty shootout",
    fee: "10",
    student1: "Joel Sebastian",
    s1_phone: "6238187879",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: false,
    venue: "Ground",
    time: "na",
    event_image: img32,
  },
  {
    event_id: 19,
    name: "Log launch",
    description: "Hit the stump with the ball.",
    event_type: "Stump throw",
    fee: "10",
    student1: "Fajas",
    s1_phone: "8848049773",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: false,
    venue: "Ground",
    time: "na",
    event_image: img38,
  },
  {
    event_id: 20,
    name: "Aim & pop",
    description: "Hit the balloon with an arrow or gun.",
    event_type: "Balloon shooting",
    fee: "10",
    student1: "Karthik Krishna",
    s1_phone: "6238321943",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: false,
    venue: "Ground",
    time: "na",
    event_image: img7,
  },
  {
    event_id: 21,
    name: "Tower of triumph",
    description: "Construct a pyramid within the given time.",
    event_type: "Pyramid construction",
    fee: "20",
    student1: "Aravind",
    s1_phone: "8075658064",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: false,
    venue: "Ground",
    time: "na",
    event_image: img8,
  },
  {
    event_id: 22,
    name: "Steady spark",
    description: "Pass through the wire without getting buzzed.",
    event_type: "Buzz wire",
    fee: "20",
    student1: "Asheen",
    s1_phone: "8848539535",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: false,
    venue: "Ground",
    time: "na",
    event_image: img10,
  },
  {
    event_id: 23,
    name: "Twist and solve",
    description: "Solve the given Rubik's cube.",
    event_type: "Rubik's cube solving",
    fee: "10",
    student1: "Adhitya Venugopal",
    s1_phone: "6238303965",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: false,
    venue: "Ground",
    time: "na",
    event_image: img11,
  },
  {
    event_id: 24,
    name: "Triple threat",
    description: "Flip the cup 3 in-a-row.",
    event_type: "FLIP TAC TOE",
    fee: "0",
    student1: "Muhammed Azeez",
    s1_phone: "9188204959",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: false,
    venue: "Electrical lab complex",
    time: "na",
    event_image: img23,
  },
  {
    event_id: 25,
    name: "Step & Steady",
    description: "Move the cup by flipping the bottle.",
    event_type: "WALKER BOTTLE",
    fee: "20",
    student1: "Shivang",
    s1_phone: "8547120207",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: false,
    venue: "Electrical lab complex",
    time: "na",
    event_image: img26,
  },
  {
    event_id: 26,
    name: "Flickmaster",
    description: "Strike the red coin to the pocket.",
    event_type: "Carrom board",
    fee: "50",
    student1: "Muhammad Azeez",
    s1_phone: "9188204959",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: false,
    venue: "Electrical lab complex",
    time: "na",
    event_image: img32,
  },
  {
    event_id: 27,
    name: "Unlock It",
    description: "Finding the key for the lock given.",
    event_type: "Lock and Key",
    fee: "20",
    student1: "Alvin Bosco",
    s1_phone: "8590674402",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "Electrical lab complex",
    time: "na",
    event_image: img7,
  },
  {
    event_id: 28,
    name: "Feel The Pain",
    description:
      "Experiencing the pain during the periods using the period cramps equipment.",
    event_type: "Periods Cramps",
    fee: "20",
    student1: "Shivang",
    s1_phone: "8547120207",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: false,
    venue: "S3 EEE",
    time: "na",
    event_image: img38,
  },
  {
    event_id: 29,
    name: "Desi Combat",
    description: "Online game.",
    event_type: "Battle ground India",
    fee: "200",
    student1: "Vishnu Balagopalan",
    s1_phone: "7012563857",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "Electrical lab complex",
    time: "na",
    event_image: img7,
  },
  {
    event_id: 30,
    name: "Fire Strike",
    description: "Online game.",
    event_type: "Free fire",
    fee: "200",
    student1: "Lisa Mary Mathew",
    s1_phone: "9446062140",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: true,
    venue: "Electrical lab complex",
    time: "na",
    event_image: img8,
  },
  {
    event_id: 31,
    name: "Hunt For Glory",
    description: "Finding the treasure.",
    event_type: "Treasure hunt",
    fee: "150",
    student1: "Smera Sanil",
    s1_phone: "9037385919",
    staff: "Ms.Tintu George",
    prize: "Yes",
    dept: "eee",
    reg: false,
    group: false,
    venue: "Basketball gate",
    time: "na",
    event_image: img11,
  },
  {
    event_id: 32,
    name: "Project Exhibition - S3",
    description: "Showcasing S3 student projects.",
    event_type: "Expo",
    fee: "0",
    student1: "Abin CS",
    s1_phone: "7510565132",
    staff: "Ms.Tintu George",
    prize: "na",
    dept: "eee",
    reg: false,
    group: false,
    venue: "na",
    time: "na",
    event_image: img8,
  },
  {
    event_id: 33,
    name: "Mahindra Exhibition",
    description: "Exhibition by Mahindra.",
    event_type: "Expo",
    fee: "0",
    student1: "Jithin",
    s1_phone: "9207802727",
    staff: "na",
    prize: "na",
    dept: "eee",
    reg: false,
    group: false,
    venue: "na",
    time: "na",
    event_image: img26,
  },
  {
    event_id: 34,
    name: "Decoration and Flux",
    description: "Decoration event for the techfest.",
    event_type: "Expo",
    fee: "0",
    student1: "na",
    s1_phone: "na",
    staff: "na",
    prize: "na",
    dept: "eee",
    reg: false,
    group: false,
    venue: "na",
    time: "na",
    event_image: img32,
  },
  {
    event_id: 35,
    name: "Project Exhibition - S5",
    description: "Showcasing S5 student projects.",
    event_type: "Expo",
    fee: "0",
    student1: "Abin CS",
    s1_phone: "7510565132",
    staff: "na",
    prize: "na",
    dept: "eee",
    reg: false,
    group: false,
    venue: "na",
    time: "na",
    event_image: img38,
  },
  {
    event_id: 36,
    name: "Project Exhibition - S7",
    description: "Showcasing S7 student projects.",
    event_type: "Expo",
    fee: "0",
    student1: "Abin CS",
    s1_phone: "7510565132",
    staff: "na",
    prize: "na",
    dept: "eee",
    reg: false,
    group: false,
    venue: "na",
    time: "na",
    event_image: img7,
  },
  {
    event_id: 37,
    name: "Project Exhibition - General",
    description: "Exhibition of various projects by students.",
    event_type: "Expo",
    fee: "0",
    student1: "Abin CS",
    s1_phone: "7510565132",
    staff: "na",
    prize: "na",
    dept: "eee",
    reg: false,
    group: false,
    venue: "na",
    time: "na",
    event_image: img8,
  },
  {
    event_id: 38,
    name: "Tata Exhibition",
    description: "Exhibition by Tata.",
    event_type: "Expo",
    fee: "0",
    student1: "Jithin",
    s1_phone: "9207802727",
    staff: "na",
    prize: "na",
    dept: "eee",
    reg: false,
    group: false,
    venue: "na",
    time: "na",
    event_image: img10,
  },

  {
    event_id: 39,
    name: "MG Exhibition",
    description: "Exhibition by MG.",
    event_type: "Expo",
    fee: "0",
    student1: "Jithin",
    s1_phone: "9207802727",
    staff: "na",
    prize: "na",
    dept: "eee",
    reg: false,
    group: false,
    venue: "na",
    time: "na",
    event_image: img14,
  },
  {
    event_id: 40,
    name: "Ather Exhibition",
    description: "Exhibition by Ather.",
    event_type: "Expo",
    fee: "0",
    student1: "Jithin",
    s1_phone: "9207802727",
    staff: "na",
    prize: "na",
    dept: "eee",
    reg: false,
    group: false,
    venue: "na",
    time: "na",
    event_image: img23,
  },
  {
    event_id: 41,
    name: "TVS Exhibition",
    description: "Exhibition by TVS.",
    event_type: "Expo",
    fee: "0",
    student1: "Jithin",
    s1_phone: "9207802727",
    staff: "na",
    prize: "na",
    dept: "eee",
    reg: false,
    group: false,
    venue: "na",
    time: "na",
    event_image: img26,
  },
];

export default EeeEventData;
