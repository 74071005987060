import img1 from "../../assets/department_images/CSD/1.png";
import img2 from "../../assets/department_images/CSD/2.png";
import img3 from "../../assets/department_images/CSD/3.png";
import img4 from "../../assets/department_images/CSD/4.png";
import img5 from "../../assets/department_images/CSD/5.png";
import img6 from "../../assets/department_images/CSD/6.png";

const csdEventData = [
  {
    event_id: 801,
    name: "Gaming Room - Car Simulator AutoArcade",
    description:
      "A room filled with various video games like FIFA, car racing, and more, enhanced with ambient lighting and immersive sound effects.",
    event_type: "Experience",
    fee: "30",
    student1: "Abhiram Krishna",
    s1_phone: "8848656908",
    staff: "Ms Rosebell Jhon",
    prize: false,
    dept: "csd",
    reg: false,
    group: false,
    venue: "Admin Block, Ground Floor, S7 CSD Classroom",
    time: "09:30",
    event_image: img1,
  },
  {
    event_id: 802,
    name: "AR Treasure Hunt - The Quest",
    description:
      "A treasure hunt using augmented reality (AR) conducted throughout the college campus.",
    event_type: "Competition",
    fee: "50",
    student1: "Ujwel C",
    s1_phone: "9847346284",
    staff: "Ms Anju Ashokan",
    prize: true,
    dept: "csd",
    reg: false,
    group: false,
    venue: "Admin Block, Ground Floor, S5 CSD Classroom",
    time: "11:00",
    event_image: img2,
  },
  {
    event_id: 803,
    name: "Game Forge",
    description: "Students are required to create a 3D game using Unity 3D.",
    event_type: "Competition",
    fee: "50",
    student1: "Salil M P",
    s1_phone: "9895288774",
    staff: "Mr Akhil KK",
    prize: true,
    dept: "csd",
    reg: false,
    group: false,
    venue: "Admin Block, Second Floor, ACRC Lab",
    time: "10:30",
    event_image: img3,
  },
  {
    event_id: 804,
    name: "Next Dimension",
    description:
      "Step into a new dimension with our virtual reality experience.",
    event_type: "Experience",
    fee: "20",
    student1: "Sidharth Ram",
    s1_phone: "8921121988",
    staff: "Ms Shruthi",
    prize: false,
    dept: "csd",
    reg: false,
    group: false,
    venue: "Admin Block, Ground Floor, S5 CSD Classroom",
    time: "09:30 - 15:30",
    event_image: img4,
  },
  {
    event_id: 805,
    name: "Cutting Edge",
    description:
      "Participants create a corporate video based on a company or institution.",
    event_type: "Competition",
    fee: "20",
    student1: "Alok Soman K",
    s1_phone: "8138889766",
    staff: "Ms Silna KV",
    prize: true,
    dept: "csd",
    reg: false,
    group: false,
    venue: "Admin Block, Second Floor, Language Lab",
    time: "10:30",
    event_image: img5,
  },
  {
    event_id: 806,
    name: "Vibefx",
    description: "Prepare a motion graphics video based on a storyboard.",
    event_type: "Competition",
    fee: "20",
    student1: "Afsal tp",
    s1_phone: "9747123093",
    staff: "Ms Neethu VK",
    prize: true,
    dept: "csd",
    reg: false,
    group: false,
    venue: "Lnguage lab",
    time: "10:00",
    event_image: img6,
  },
  {
    event_id: 807,
    name: "Xoaxis",
    description: "X and O game",
    event_type: "Competition",
    fee: "0",
    student1: "Stephy",
    s1_phone: "8891251199",
    staff: "Mr akhil kk",
    prize: true,
    dept: "csd",
    reg: false,
    group: false,
    venue: "S5 csd",
    time: "10:00",
    event_image: img3,
  },
  {
    event_id: 808,
    name: "Immersia",
    description: "Vr Experiences",
    event_type: "Competition",
    fee: "0",
    student1: "Nandhu sajeveen",
    s1_phone: "9895713324",
    staff: "Ms Athria CV",
    prize: true,
    dept: "csd",
    reg: false,
    group: false,
    venue: "S5 csd",
    time: "10:00",
    event_image: img2,
  },
];
export default csdEventData;
